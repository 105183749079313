import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

import BonsCommande from './index';

const PageBonsCommandeEnAttente: FC<
  PageProps & EspaceProps & UserProps
> = props => <BonsCommande {...props} pageContext={{ tab: 1 }} />;

export default requireEspace(PageBonsCommandeEnAttente);
